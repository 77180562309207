import React, { useEffect } from 'react';
import classnames from 'classnames';

import Loader from 'components/uiLibrary/Loader';
import Typography from 'components/uiLibrary/Typography';
import { enqueueSnackbar, SNACKBAR_VARIANTS } from 'components/uiLibrary/Snackbar';

import { useUserData } from 'utils/hooks/useAuthenticatedUser';
import { sentryCaptureMessage } from 'utils/globals/sentry';
import usePageContext from 'utils/hooks/usePageContext';
import { TP } from 'constants/index';
import { useTranslation } from 'src/i18n';
import classes from './RegistrationLoader.module.scss';

const RegistrationLoader = ({ title, subTitle, isModal, redirectToHome = false, isError = false }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { navigate } = usePageContext();
  const userData = useUserData();

  // NOTE: This is fallback if anything bad happens during registration
  useEffect(() => {
    if (redirectToHome) {
      return undefined;
    }
    const pleaseWaitTimeout = setTimeout(() => {
      enqueueSnackbar(t(`${TP}.PLEASE_WEIGHT_FALL_BACK`), { variant: SNACKBAR_VARIANTS.INFO });
    }, 30000); // NOTE: Show "Please wait" after 30 seconds

    // NOTE: Timeout for redirection after 60 seconds
    const redirectTimeout = setTimeout(() => {
      enqueueSnackbar(t(`${TP}.ERROR_FALL_BACK_1_REGISTRATION`), {
        variant: SNACKBAR_VARIANTS.ERROR,
      });
      sentryCaptureMessage('Registration issue:', {
        extra: {
          context: {
            userId: userData?.id,
            source: 'RegistrationLoader',
          },
        },
      });
      navigate.to(navigate.getLinkProps({}));
    }, 60000);

    // NOTE: Cleanup timeouts on unmount
    return () => {
      clearTimeout(pleaseWaitTimeout);
      clearTimeout(redirectTimeout);
    };
  }, [navigate, redirectToHome, t, userData?.id]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t(`${TP}.ERROR_FALL_BACK_1_REGISTRATION`), {
        variant: SNACKBAR_VARIANTS.ERROR,
      });
    }
    if (redirectToHome || isError) {
      navigate.to(navigate.getLinkProps({}));
    }
  }, [redirectToHome, isError]);

  return (
    <Loader className={classnames(classes.loader, { [classes.loaderModal]: isModal })}>
      {title && (
        <Typography className={classes.loader__title} color="secondary" size="12" weight="medium" variant="div">
          {title}
        </Typography>
      )}
      {redirectToHome && (
        <Typography className={classes.loader__title} color="secondary" size="12" weight="medium" variant="div">
          {t(`${TP}.YOU_ARE_LOGGEDIN_ALREADY`)}
        </Typography>
      )}
      <Typography size="18" weight="bold" variant="div">
        {subTitle}
      </Typography>
    </Loader>
  );
};

export default RegistrationLoader;
