import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { withTranslation, getPageTranslations } from 'src/i18n';

import { PAGE_CATEGORY, PAGE_TYPES } from 'components/Globals/Analytics/constants';
import AccountsWrapper from 'components/Globals/Accounts/AccountsWrapper';
import AuthentificationLayout from 'components/Globals/Accounts/AuthentificationLayout';
import RegistrationLoader from 'components/Globals/Accounts/Common/RegistrationLoader';

import { nextPageQueryName } from 'utils/auth';
import useAppContext from 'utils/hooks/useAppContext';
import usePageContext from 'utils/hooks/usePageContext';

import classes from './LoginPage.module.scss';

const i18nNamespaces = getPageTranslations(['translation']);

const LoginPage = () => {
  const { isLoggedIn } = useAppContext();
  const { navigate } = usePageContext();
  const Router = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      setIsLoading(true);
      const nextPage = Router?.query?.[nextPageQueryName];

      if (nextPage) {
        const sanitizedNextPage = nextPage.replace(/#.*/, '');
        window.location.href = sanitizedNextPage;
      } else {
        navigate.to(navigate.getLinkProps({}));
      }
    }
  }, [isLoggedIn]);

  const loginContent = (
    <div className={classes.loginWrapper}>
      {isLoading ? <RegistrationLoader /> : <AccountsWrapper isNewUser={false} isModal={false} />}
    </div>
  );

  return <AuthentificationLayout className={classes.newFlow}>{loginContent}</AuthentificationLayout>;
};

LoginPage.getInitialProps = async ctx => {
  const { query } = ctx;
  const isForgetPasswordPage = query && query.page === 'forgotPassword';

  return {
    namespacesRequired: i18nNamespaces,
    isForgetPasswordPage,
    pageCategory: PAGE_CATEGORY.LANDING,
    pageType: PAGE_TYPES.LOGIN,
  };
};

export default withTranslation(i18nNamespaces)(LoginPage);
